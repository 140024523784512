.Advertising {
    padding: 0;
    margin: 0;
    height: 100%;
    overflow: hidden;

    .wrapper {
        padding: 50px 0;

        .slick-prev {
            left: 5vw;
            z-index: 101;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            background: rgba(217, 217, 217, 0.15);
            border: 0.783784px solid rgba(255, 255, 255, 0.7);
            backdrop-filter: blur(3.5725px);
            display: flex !important;
            align-items: center;
            justify-content: center;

            &:hover {
                &::after {
                    opacity: 0.6;
                }
            }

            &::after {
                width: 200%;
                height: 200%;
                content: "";
                z-index: -1;
                opacity: 0;
                position: absolute;
                background: rgba(217, 217, 217, 0.15);
                border: 0.783784px solid rgba(255, 255, 255, 0.7);
                backdrop-filter: blur(3.5725px);
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                transition: 0.2s;
                -webkit-transition: 0.2s;
                -moz-transition: 0.2s;
                -ms-transition: 0.2s;
                -o-transition: 0.2s;
            }

            @media (max-width:767px) {
                width: 40px;
                height: 40px;
                left: 20px;
            }

            @media (max-width:500px) {
                left: 15px;
                width: 30px;
                height: 30px;
            }
        }

        .slick-prev:before {
            margin-right: 5px;
            content: "\f0d9";
            font-family: FontAwesome;
            font-style: normal;
            border-radius: 50%;
            font-weight: 100;
            color: white;
            font-size: 35px;
            font-weight: 900;

            @media (max-width:767px) {
                font-size: 27px;
            }

            @media (max-width:500px) {
                font-size: 20px;
            }
        }

        .slick-next {
            right: 5vw;
            z-index: 101;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            background: rgba(217, 217, 217, 0.15);
            border: 0.783784px solid rgba(255, 255, 255, 0.7);
            backdrop-filter: blur(3.5725px);
            display: flex !important;
            align-items: center;
            justify-content: center;
            text-align: center;

            &:hover {
                &::after {
                    opacity: 0.6;
                }
            }

            &::after {
                width: 200%;
                height: 200%;
                content: "";
                z-index: -1;
                opacity: 0;
                position: absolute;
                background: rgba(217, 217, 217, 0.15);
                border: 0.783784px solid rgba(255, 255, 255, 0.7);
                backdrop-filter: blur(3.5725px);
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                transition: 0.2s;
                -webkit-transition: 0.2s;
                -moz-transition: 0.2s;
                -ms-transition: 0.2s;
                -o-transition: 0.2s;
            }

            @media (max-width:767px) {
                width: 40px;
                height: 40px;
                right: 20px;
            }
            @media (max-width:500px) {
                width: 30px;
                height: 30px;
                right: 15px;
            }

        }

        .slick-next:before {
            content: "\f0da";
            font-family: FontAwesome;
            font-style: normal;
            font-weight: 100;
            color: white;
            font-size: 35px;
            font-weight: 900;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width:767px) {
                font-size: 27px;
            }

            @media (max-width:500px) {
                font-size: 20px;
            }
        }

        .slick-dots {
            bottom: 30px;
            opacity: 1;
            z-index: 3;
            display: flex;
            align-items: center;
            justify-content: center;

            li {
                padding: 0 10px;

                button {
                    &::before {
                        width: 11px;
                        height: 11px;
                        display: flex;
                        color: white;
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                        background-color: white;

                        @media (max-width:1000px) {
                            width: 9px;
                            height: 9px;
                        }

                        @media (max-width:500px) {
                            width: 8px;
                            height: 8px;
                        }
                    }
                }
            }

            .slick-active {
                transition: 0.5s;

                button {
                    transition: 0.5s;

                    &::before {
                        opacity: 1;
                        width: 30px;
                        height: 11px;
                        display: flex;
                        transition: 0.5s;
                        margin-left: -8px;
                        border-radius: 6px;
                        align-items: center;
                        justify-content: center;
                        background-color: white;

                        @media (max-width:1000px) {
                            width: 22px;
                            height: 8px;
                            border-radius: 4px;
                        }

                        @media (max-width:500px) {
                            width: 20px;
                            height: 7px;
                            border-radius: 4px;
                        }
                    }
                }
            }
        }

        .col-md-12 {
            width: 100%;

            .body {
                position: relative;
                width: 100%;
                overflow: hidden;
                height: 90vh;
                text-align: center;
                display: grid;
                align-items: center;
                padding: 30px 13vw;
                padding-right: 30vw;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                @media (max-width:1200px) {
                    padding: 30px 13vw;
                }

                @media (max-width:767px) {
                    height: 600px;
                }
                @media (max-width:500px) {
                    height: 500px;
                    padding: 20px 60px;
                }

                img {
                    top: 0;
                    left: 0;
                    z-index: -1;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: absolute;
                    filter: brightness(0.6);
                    -webkit-filter: brightness(0.6);
}

                h1 {
                    color: white;
                    text-align: left;
                    font-size: 3.1vw;

                    @media (max-width:1200px) {
                        font-size: 3.5vw;
                    }

                    @media (max-width:767px) {
                        font-size: 5vw;
                    }
                    @media (max-width:500px) {
                        font-size: 27px;
                    }
                }

                p {
                    margin-top: 2vw;
                    color: white;
                    text-align: left;
                    font-size: 1.3vw;

                    @media (max-width:1200px) {
                        font-size: 1.7vw;
                    }

                    @media (max-width:767px) {
                        font-size: 2.3vw;
                    }
                    @media (max-width:500px) {
                        font-size: 14px;
                    }

                }

                .explore {
                    margin-top: 2vw;
                    color: #FFDF3A;
                    font-size: 18px;

                    @media (max-width:767px) {
                        font-size: 16px;
                    }

                    @media (max-width:500px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}