.Header {
    padding: 0;
    margin: 0;
    height: 100%;
    overflow: hidden;
    // background: linear-gradient(134.12deg, #E4FCFA 0%, #E4FDF5 100%);       
    background-color: white;

    .wrapper {
        padding: 0;
        padding-top: 7.3vw;
        padding-bottom: 26px;

        @media (max-width:1150px) {
            padding-top: 110px;
        }

        @media (max-width:900px) {
            padding-bottom: 24px;
        }

        @media (max-width:767px) {
            padding-top: 95px;
        }

        @media (max-width:500px) {
            padding-bottom: 16px;
        }

        .carousel-slide {
            .slider {
                .slider-page {
                    padding: 0;
                    width: 100%;

                    .body {
                        width: 100%;
                        height: 30vw;
                        display: flex;
                        overflow: hidden;
                        padding: 5vw 10vw;
                        position: relative;
                        padding-right: 40vw;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: space-between;

                        @media (max-width:900px) {
                            height: 35vw;
                            padding: 4vw 10vw;
                            padding-right: 25vw;
                        }

                        @media (max-width:600px) {
                            height: 50vw;
                            padding: 30px 7vw;
                            padding-right: 10vw;
                        }

                        @media (max-width:450px) {
                            height: 60vw;
                        }

                        img {
                            top: 0;
                            left: 0;
                            width: 100%;
                            z-index: -1;
                            height: 100%;
                            object-fit: cover;
                            position: absolute;
                        }

                        .title {
                            font-size: 3.5vw;

                            @media (max-width:900px) {
                                font-size: 4vw;
                            }

                            @media (max-width:600px) {
                                font-size: 5.5vw;
                            }
                        }

                        .desc {
                            font-size: 1.3vw;
                            font-weight: 700;

                            @media (max-width:900px) {
                                font-size: 15px;
                            }

                            @media (max-width:500px) {
                                font-size: 14px;
                            }

                            @media (max-width:350px) {
                                font-size: 13px;
                            }
                        }

                        .link {
                            width: 100%;
                            display: flex;
                            font-weight: 700;
                            align-items: center;
                            justify-content: start;

                            .explore {
                                color: black;
                                padding: 7px 30px;
                                border-radius: 5px;
                                -webkit-border-radius: 5px;
                                -moz-border-radius: 5px;
                                -ms-border-radius: 5px;
                                -o-border-radius: 5px;
                                text-decoration: none;
                                background-color: white;

                                &:hover {
                                    background-color: #FFC400 !important;
                                }

                                @media (max-width:900px) {
                                    font-size: 15px;
                                    padding: 5px 25px;
                                }

                                @media (max-width:500px) {
                                    font-size: 14px;
                                    padding: 3px 15px;
                                }
                            }
                        }
                    }
                }

                .slick-prev {
                    opacity: 0;
                    left: 0px;
                    z-index: 101;
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    border: solid 1px #1DE3CC;
                    background: #E5FDF7;

                    @media (max-width:500px) {
                        width: 25px;
                        height: 25px;
                        left: 0px;
                    }
                }

                .slick-prev:before {
                    content: "\f104";
                    font-family: FontAwesome;
                    font-style: normal;
                    border-radius: 50%;
                    font-weight: 100;
                    color: #1DE3CC;
                    font-size: 35px;
                    font-weight: 900;

                    @media (max-width:500px) {
                        font-size: 20px;
                    }
                }

                .slick-next {
                    right: 0;
                    opacity: 0;
                    z-index: 101;
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    border: solid 1px #1DE3CC;
                    background: #E5FDF7;

                    @media (max-width:500px) {
                        width: 25px;
                        height: 25px;
                    }
                }

                .slick-next:before {
                    content: "\f105";
                    font-family: FontAwesome;
                    font-style: normal;
                    border-radius: 50%;
                    font-weight: 100;
                    color: #1DE3CC;
                    font-size: 35px;
                    font-weight: 900;

                    @media (max-width:500px) {
                        font-size: 20px;
                    }
                }

                .slick-dots {
                    opacity: 1;
                    z-index: 3;
                    bottom: -28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media (max-width:900px) {
                        bottom: -27px;
                    }

                    @media (max-width:500px) {
                        bottom: -24px;
                    }

                    li {
                        padding: 0 10px;

                        button {
                            &::before {
                                opacity: 1;
                                width: 9px;
                                height: 9px;
                                display: flex;
                                color: white;
                                border-radius: 50%;
                                align-items: center;
                                justify-content: center;
                                background-color: white;
                                border: solid 1px black;

                                @media (max-width:1000px) {
                                    width: 9px;
                                    height: 9px;
                                }

                                @media (max-width:500px) {
                                    width: 8px;
                                    height: 8px;
                                }
                            }
                        }
                    }

                    .slick-active {
                        transition: 0.5s;

                        button {
                            transition: 0.5s;

                            &::before {
                                color: black;
                                background-color: black;
                            }
                        }
                    }
                }
            }
        }
    }

    .lenta {
        max-width: 100%;
        min-width: 100%;
        max-height: 4vw;
        min-height: 4vw;
        object-fit: cover;

        @media (max-width:500px) {
            max-height: 6vw;
            min-height: 6vw;
        }
    }
}