.Products {
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: sticky;
    overflow: hidden;

    .wrapper {
        padding: 0 10vw;
        padding-top: 12vw;

        @media (max-width:1200px) {
            padding-top: 15vw;
        }

        @media (max-width:1000px) {
            padding-top: 18vw;
        }

        @media (max-width:767px) {
            padding: 0 7vw;
            padding-top: 140px;
        }

        @media (max-width:500px) {
            padding-top: 130px;
        }

        .top-title {
            font-size: 2.3vw;
            margin-bottom: 2vw;

            @media (max-width:1000px) {
                font-size: 2.7vw;
            }

            @media (max-width:767px) {
                font-size: 3.5vw;
                margin-bottom: 5vw;
            }

            @media (max-width:500px) {
                font-size: 4.5vw;
            }
        }

        .categories {
            gap: 1vw;
            display: flex;
            justify-content: space-between;

            .fa {
                width: 2.4vw;
                height: 2.4vw;
                display: flex;
                cursor: pointer;
                font-size: 1.5vw;
                transition: 0.2s;
                -webkit-transition: 0.2s;
                -moz-transition: 0.2s;
                -ms-transition: 0.2s;
                -o-transition: 0.2s;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                align-items: center;
                justify-content: center;
                border: solid 2px black;

                @media (max-width:1000px) {
                    width: 3.1vw;
                    height: 3.1vw;
                    font-size: 2vw;
                    border-width: 1px;
                }

                @media (max-width:767px) {
                    width: 4.1vw;
                    height: 4.1vw;
                    font-size: 2.5vw;
                }

                @media (max-width:450px) {
                    width: 6vw;
                    height: 6vw;
                    font-size: 3.7vw;
                }
            }

            .rotating {
                transform: rotate(90deg);
                -webkit-transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                -o-transform: rotate(90deg);
            }

            .tools {
                gap: 0.7vw;
                height: 2.6vw;
                display: flex;
                flex-wrap: wrap;
                overflow: hidden;

                @media (max-width:1000px) {
                    height: 3.5vw;
                }

                @media (max-width:767px) {
                    gap: 1vw;
                    height: 4.6vw;
                }

                @media (max-width:450px) {
                    height: 6.6vw;
                }
            }

            .open {
                height: auto;
            }

            .category {
                color: black;
                font-size: 1vw;
                padding: 0.4vw 1vw;
                border-radius: 50px;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                -ms-border-radius: 50px;
                -o-border-radius: 50px;
                text-decoration: none;
                border: solid 1px black;

                &:hover {
                    background-color: #FBC100;
                }

                @media (max-width:1000px) {
                    font-size: 1.5vw;
                }

                @media (max-width:767px) {
                    font-size: 2vw;
                }

                @media (max-width:450px) {
                    font-size: 3vw;
                }
            }

            .active-category {
                background-color: #FBC100;
            }
        }

        .sub-categories {
            gap: 1vw;
            display: flex;
            margin-top: 2vw;
            margin-bottom: 3vw;
            justify-content: space-between;

            @media (max-width:767px) {
                margin-top: 4vw;
                margin-bottom: 5vw;
            }

            .fa {
                width: 2.4vw;
                height: 2.4vw;
                display: flex;
                cursor: pointer;
                font-size: 1.5vw;
                transition: 0.2s;
                -webkit-transition: 0.2s;
                -moz-transition: 0.2s;
                -ms-transition: 0.2s;
                -o-transition: 0.2s;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                align-items: center;
                justify-content: center;
                border: solid 2px black;

                @media (max-width:1000px) {
                    width: 3.1vw;
                    height: 3.1vw;
                    font-size: 2vw;
                    border-width: 1px;
                }

                @media (max-width:767px) {
                    width: 4.1vw;
                    height: 4.1vw;
                    font-size: 2.5vw;
                }

                @media (max-width:450px) {
                    width: 6vw;
                    height: 6vw;
                    font-size: 3.7vw;
                }
            }

            .rotating {
                transform: rotate(90deg);
                -webkit-transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                -o-transform: rotate(90deg);
            }

            .tools {
                gap: 0.7vw;
                height: 2.6vw;
                display: flex;
                flex-wrap: wrap;
                overflow: hidden;

                @media (max-width:1000px) {
                    height: 3.5vw;
                }

                @media (max-width:767px) {
                    gap: 1vw;
                    height: 4.6vw;
                }

                @media (max-width:450px) {
                    height: 6.6vw;
                }
            }

            .open {
                height: auto;
            }

            .sub-category {
                color: black;
                font-size: 1vw;
                cursor: pointer;
                padding: 0.4vw 1vw;
                border-radius: 50px;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                -ms-border-radius: 50px;
                -o-border-radius: 50px;
                text-decoration: none;
                border: solid 1px black;

                &:hover {
                    background-color: #ffdb65;
                }

                @media (max-width:1000px) {
                    font-size: 1.5vw;
                }

                @media (max-width:767px) {
                    font-size: 2vw;
                }

                @media (max-width:450px) {
                    font-size: 3vw;
                }
            }

            .active-sub-category {
                background-color: #ffdb65;
            }
        }

        .products {
            display: grid;
            gap: 3vw 1.5vw;
            grid-template-columns: repeat(4, 4fr);

            @media (max-width:1000px) {
                gap: 3vw 2vw;
                grid-template-columns: repeat(3, 3fr);
            }

            @media (max-width:767px) {
                gap: 4vw 3vw;
                grid-template-columns: repeat(2, 2fr);
            }
        }

        .paginations {
            display: flex;
            margin-top: 4vw;
            justify-content: center;

            @media (max-width:767px) {
                margin-top: 6vw;
            }

            @media (max-width:450px) {
                margin-top: 8vw;
            }
        }
    }
}