.Contacts {
    .wrapper {
        padding: 100px 10vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media (max-width:1629px) {
            padding: 100px 5vw;
        }

        @media (max-width:1555px) {
            padding: 50px 50px;
        }

        @media (max-width:1250px) {
            padding: 50px 80px;
        }

        @media (max-width:1000px) {
            padding: 50px 60px;
        }

        @media (max-width:870px) {
            padding: 30px;
        }

        .cards {
            display: flex;
            padding: 3.5vw;
            overflow: hidden;
            position: relative;
            align-items: center;
            justify-content: end;
            border-radius: 12px;
            background-color: #F8F8F8;
            -webkit-border-radius: 12px;
            -moz-border-radius: 12px;
            -ms-border-radius: 12px;
            -o-border-radius: 12px;

            .coin {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;

                @media(max-width:1000px) {
                    width: 120%;
                }

                @media(max-width:600px) {
                    width: 180%;
                }

                @media(max-width:500px) {
                    width: 190%;
                    height: 80%;
                }
            }
        }

        h1 {
            font-size: 2.2vw;

            @media(max-width:1200px) {
                font-size: 2.7vw;
            }

            @media(max-width:600px) {
                font-size: 5vw;
            }
        }

        p {
            text-align: left;
            font-size: 0.9vw;

            @media(max-width:1200px) {
                font-size: 1.3vw;
            }

            @media(max-width:600px) {
                font-size: 3vw;
            }
        }

        .forms {
            z-index: 2;
            position: sticky;
            padding-left: 2vw;

            @media(max-width:600px) {
                width: 100%;
            }
        }

        .forms-control {
            width: 100%;
            outline: none;
            padding: 20px;
            margin-top: 1.5vw;
            border: 1px solid black;
            border-radius: 18px;
            -webkit-border-radius: 18px;
            -moz-border-radius: 18px;
            -ms-border-radius: 18px;
            -o-border-radius: 18px;

            @media(max-width:1200px) {
                padding: 15px;
                border-radius: 15px;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
            }

            @media(max-width:1000px) {
                font-size: 14px;
            }

            @media(max-width:500px) {
                margin-top: 3vw;
            }
        }

        input::-webkit-inner-spin-button,
        input::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: 0;
        }

        .form-control.red-line {
            border: solid 1px red;
        }

        textarea {
            resize: none;
        }

        .btn {
            width: 100%;
            padding: 15px;
            color: white;
            margin-top: 2.2vw;
            border-radius: 50px;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            -ms-border-radius: 50px;
            -o-border-radius: 50px;
            background-color: black;
            transition: 0.3s;
            -webkit-transition: 0.3s;
            -moz-transition: 0.3s;
            -ms-transition: 0.3s;
            -o-transition: 0.3s;

            @media(max-width:600px) {
                font-size: 15px;
                padding: 10px;
                border-radius: 40px;
                -webkit-border-radius: 40px;
                -moz-border-radius: 40px;
                -ms-border-radius: 40px;
                -o-border-radius: 40px;
            }

            &:hover {
                transform: scale(1.05);
                -webkit-transform: scale(1.05);
                -moz-transform: scale(1.05);
                -ms-transform: scale(1.05);
                -o-transform: scale(1.05);
}
        }
    }
}