.Events {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .wrapper {
        padding: 70px 10vw;
        padding-bottom: 30px;

        @media (max-width:1629px) {
            padding: 50px 5vw;
            padding-bottom: 30px;
        }

        @media (max-width:1555px) {
            padding: 50px 50px;
            padding-bottom: 30px;
        }

        @media (max-width:1250px) {
            padding: 50px 80px;
            padding-bottom: 30px;
        }

        @media (max-width:1000px) {
            padding: 50px 60px;
            padding-bottom: 30px;
        }

        @media (max-width:870px) {
            padding: 50px 30px;
            padding-bottom: 30px;
        }

        @media (max-width:767px) {
            padding: 50px 30px;
            padding-bottom: 30px;
        }

        .one-new {
            margin-top: 80px;

            @media (max-width:780px) {
                margin-top: 150px;
            }

            .one-name {
                @media (max-width:600px) {
                    font-size: 24px;
                }
            }

            .one-img {
                width: 60%;
                margin-left: 20%;
                margin-top: 50px;

                @media (max-width:600px) {
                    width: 80%;
                    margin-left: 10%;
                    margin-top: 20px;
                }

                @media (max-width:600px) {
                    width: 100%;
                    margin-left: 0;
                }
            }

            .one-desc {
                margin-top: 50px;
                margin-bottom: 100px;

                @media (max-width:600px) {
                    margin-top: 20px;
                    text-align: justify;
                    font-size: 14px;
                    margin-bottom: 50px;
                }

                @media (max-width:450px) {
                    margin-bottom: 30px;
                }
            }
        }

        .title {
            color: black;
            text-align: center;

            @media (max-width:600px) {
                font-size: 24px;
            }
        }

        .carousel1 {
            // border: solid 1px red;  
            margin-top: 30px;
            display: flex;
            flex-wrap: wrap;

            .news {
                padding: 1.6vw;
                // border: solid 1px;

                @media (max-width:600px) {
                    width: 50%;
                }

                @media (max-width:450px) {
                    padding: 0;
                }

                .body {
                    width: 100%;
                    border-radius: 10px;
                    height: 100%;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    text-decoration: none;
                    align-items: flex-start;
                    justify-content: space-between;
                    padding: 1.3vw;
                    transition: 0.3s;
                    -webkit-border-radius: 10px;
                    -moz-border-radius: 10px;
                    -ms-border-radius: 10px;
                    -o-border-radius: 10px;
                    -webkit-transition: 0.3s;
                    -moz-transition: 0.3s;
                    -ms-transition: 0.3s;
                    -o-transition: 0.3s;

                    @media (max-width:600px) {
                        padding: 15px;
                    }

                    &:hover {
                        box-shadow: 0px 0px 43.357px rgba(25, 90, 148, 0.15);
                    }

                    img {
                        max-width: 100%;
                        min-width: 100%;
                        max-height: 15vw;
                        min-height: 15vw;
                        border-radius: 6px;
                        -webkit-border-radius: 6px;
                        -moz-border-radius: 6px;
                        -ms-border-radius: 6px;
                        -o-border-radius: 6px;

                        @media (max-width:600px) {
                            max-height: 25vw;
                            min-height: 25vw;
                        }
                    }

                    .name {
                        // border: solid 1px;
                        margin-top: 15px;
                        overflow: hidden;
                        color: black;

                        @media (max-width:900px) {
                            margin-top: 10px;
                            font-size: 14px;
                            margin-bottom: 3px;
                        }
                    }

                    .desc {
                        // border: solid 1px;
                        overflow: hidden;
                        color: black;
                        opacity: 0.7;
                        font-size: 15px;

                        @media (max-width:900px) {
                            font-size: 12px;
                            margin-bottom: 3px;
                        }
                    }

                    .explore {
                        color: black;
                        transition: 0.3s;
                        padding: 3px 30px;
                        border: solid 1px black;
                        border-radius: 15px;
                        -webkit-border-radius: 30px;
                        -moz-border-radius: 15px;
                        -ms-border-radius: 15px;
                        -o-border-radius: 15px;
                        -webkit-border-radius: 15px;

                        &:hover {
                            background-color: #FFC400;
                        }

                        @media (max-width:900px) {
                            font-size: 13px;
                            padding: 3px 20px;
                        }

                        @media (max-width:450px) {
                            font-size: 12px;
                            padding: 1px 10px;
                        }
                    }
                }
            }
        }

        .carousel {
            // border: solid 1px red;  
            margin-top: 30px;

            @media (max-width:700px) {
                margin-top: 10px;
            }

            .news {
                // border: solid 1px dodgerblue;
                padding: 10px 20px;

                @media (max-width:390px) {
                    padding: 10px 10px;
                }

                .body {
                    border-radius: 5px;
                    overflow: hidden;

                    // border: solid 1px rgb(201, 201, 201);
                    img {
                        max-width: 100%;
                        min-width: 100%;
                        max-height: 250px;
                        min-height: 250px;
                        top: 0;
                        left: 0;

                        @media (max-width:1200px) {
                            max-height: 200px;
                            min-height: 200px;
                        }

                        @media (max-width:700px) {
                            max-height: 150px;
                            min-height: 150px;
                        }

                        @media (max-width:450px) {
                            max-height: 120px;
                            min-height: 120px;
                        }
                    }

                    .name {
                        // border: solid 1px;
                        margin-top: 15px;
                        height: 21px;
                        overflow: hidden;
                        color: black;

                        @media (max-width:700px) {
                            font-size: 14px;
                            margin-bottom: 3px;
                        }
                    }

                    .desc {
                        // border: solid 1px;
                        overflow: hidden;
                        height: 47px;
                        color: black;
                        opacity: 0.7;
                        font-size: 15px;

                        @media (max-width:700px) {
                            font-size: 12px;
                            margin-bottom: 3px;
                            height: 37px;
                        }
                    }

                    .more {
                        color: black;
                        transition: 0.3s;

                        &:hover {
                            margin-left: 15px;
                        }

                        .right-icon {
                            font-size: 16px;

                            @media (max-width:700px) {
                                font-size: 13px;
                            }
                        }

                        @media (max-width:700px) {
                            font-size: 13px;
                        }
                    }
                }
            }

            .slick-dots {
                opacity: 0;
            }

            .slick-prev {
                left: -20px;
                z-index: 101;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                border: solid 1px black;
                background: #FBC100;

                @media (max-width:500px) {
                    width: 25px;
                    height: 25px;
                    left: -12.5px;
                }
            }

            .slick-prev:before {
                content: "\f104";
                font-family: FontAwesome;
                font-style: normal;
                border-radius: 50%;
                font-weight: 100;
                color: black;
                font-size: 35px;
                font-weight: 900;

                @media (max-width:500px) {
                    font-size: 20px;
                }
            }

            .slick-next {
                right: -20px;
                z-index: 101;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                border: solid 1px black;
                background: #FBC100;

                @media (max-width:500px) {
                    width: 25px;
                    height: 25px;
                }
            }

            .slick-next:before {
                content: "\f105";
                font-family: FontAwesome;
                font-style: normal;
                border-radius: 50%;
                font-weight: 100;
                color: black;
                font-size: 35px;
                font-weight: 900;

                @media (max-width:500px) {
                    font-size: 20px;
                }
            }
        }

        .gallery {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 30px;

            .gal-tool {
                padding: 20px 0;
                padding-right: 20px;

                @media (max-width:430px) {
                    padding: 10px 0;
                    padding-right: 10px;
                }

                .body {
                    // border: solid 1px rgb(230, 230, 230);
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                    height: 600px;
                    position: relative;

                    @media (max-width:1300px) {
                        height: 550px;
                    }

                    @media (max-width:1150px) {
                        height: 500px;
                    }

                    @media (max-width:900px) {
                        height: 400px;
                    }

                    @media (max-width:700px) {
                        height: 300px;
                    }

                    @media (max-width:500px) {
                        height: 200px;
                    }

                    @media (max-width:430px) {
                        height: 180px;
                    }

                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                    }

                    &:hover {
                        .contr-event {
                            opacity: 1;
                        }
                    }
                }
            }

            .gal-tool1.col-8 {
                padding-right: 20px;

                @media (max-width:430px) {
                    padding-right: 10px;
                }
            }

            .gal-tool1 {
                padding: 10px 0;

                .body {
                    // border: solid 1px rgb(230, 230, 230);
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                    height: 400px;
                    position: relative;

                    @media (max-width:1300px) {
                        height: 380px;
                    }

                    @media (max-width:1150px) {
                        height: 340px;
                    }

                    @media (max-width:900px) {
                        height: 270px;
                    }

                    @media (max-width:700px) {
                        height: 200px;
                    }

                    @media (max-width:500px) {
                        height: 150px;
                    }

                    @media (max-width:430px) {
                        height: 130px;
                    }

                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                    }

                    &:hover {
                        .contr-event {
                            opacity: 1;
                        }
                    }
                }
            }

            .col-12 {
                // border: solid 1px rgb(230, 230, 230);
                box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                position: relative;
                height: 290px;
                margin-top: 20px;

                @media (max-width:1300px) {
                    height: 265px;
                }

                @media (max-width:1150px) {
                    height: 240px;
                }

                @media (max-width:900px) {
                    height: 190px;
                }

                @media (max-width:700px) {
                    height: 140px;
                }

                @media (max-width:500px) {
                    height: 90px;
                }

                @media (max-width:430px) {
                    height: 85px;
                    margin-top: 10px;
                }

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }

                &:hover {
                    .contr-event {
                        opacity: 1;
                    }
                }
            }

            .contr-event {
                opacity: 0;
                position: absolute;
                z-index: 2;
                background-color: rgba(0, 0, 0, 0.469);
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                text-decoration: none;
                padding: 40px;
                transition: 0.3s;

                .name-gallery {
                    color: white;
                }

                .desc-gallery {
                    // border: solid 1px wheat;
                    color: white;
                    opacity: 0.7;
                    margin-top: 20px;
                    height: 120px;
                    overflow: hidden;
                }

                .more {
                    color: white;
                    transition: 0.3s;

                    &:hover {
                        margin-left: 15px;
                    }

                    .right-icon {
                        font-size: 16px;

                        @media (max-width:700px) {
                            font-size: 13px;
                        }
                    }

                    @media (max-width:700px) {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}