.Navbar1 {
    margin: 0;
    padding: 0;
    width: 100vw;
    z-index: 111111;
    position: fixed;

    .navbar {
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        .top-navbar {
            display: flex;
            padding: 10px 10vw;
            align-items: center;
            background-color: #F7F7F7;
            justify-content: space-between;

            @media (max-width:900px) {
                padding: 10px 7vw;
            }

            @media (max-width:767px) {
                flex-wrap: wrap;
                padding: 10px 5vw;
                padding-bottom: 5px;
            }

            .logo {
                width: 6vw;
                padding-right: 10px;

                @media (max-width:1000px) {
                    width: 8vw;
                }

                @media (max-width:500px) {
                    padding: 0;
                    width: 14vw;
                }

                img {
                    max-width: 100%;
                    min-width: 100%;
                }
            }

            .search-bar {
                width: 43vw;
                z-index: 3;
                position: sticky;

                @media (max-width:500px) {
                    width: 60vw;
                }

                input {
                    width: 100%;
                    outline: none;
                    padding: 0.5vw;
                    font-weight: 600;
                    border-radius: 5px;
                    font-family: zonapro;
                    border: solid 2px #FBC100;

                    @media (max-width:500px) {
                        font-size: 13px;
                        padding: 3px 10px;
                    }
                }

                .fa-search {
                    top: 0;
                    right: 0;
                    height: 100%;
                    display: flex;
                    color: black;
                    padding: 0 15px;
                    font-size: 20px;
                    cursor: pointer;
                    position: absolute;
                    align-items: center;
                    justify-content: center;
                    background-color: #FBC100;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;

                    @media (max-width:1000px) {
                        font-size: 18px;
                        padding: 0 10px;
                    }

                    @media (max-width:500px) {
                        font-size: 15px;
                    }
                }

                .search-filter {
                    padding: 0;
                    width: 100%;
                    overflow: auto;
                    margin-top: 5px;
                    max-height: 500px;
                    position: absolute;
                    border-radius: 7px;
                    background-color: white;
                    border: solid 1px rgb(220, 220, 220);

                    @media (max-width:767px) {
                        max-height: 400px;
                    }

                    li {
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        list-style: none;

                        .result {
                            width: 100%;
                            color: black;
                            transition: 0.3s;
                            padding: 5px 20px;
                            text-decoration: none;
                            transition: 0 !important;
                            -webkit-transition: 0 !important;
                            -moz-transition: 0 !important;
                            -ms-transition: 0 !important;
                            -o-transition: 0 !important;

                            @media (max-width:767px) {
                                font-size: 14px;
                            }

                            @media (max-width:500px) {
                                font-size: 13px;
                            }

                            &:hover {
                                background-color: #FFC400;
                            }
                        }
                    }
                }
            }

            .navbar-nav {
                @media (max-width:767px) {
                    padding-top: 10px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .nav-item {
                    display: flex;
                    padding: 0 1.2vw;
                    align-items: center;
                    justify-content: center;

                    .nav-link {
                        color: black;
                        cursor: pointer;
                        position: relative;

                        i {
                            color: black;
                            font-size: 28px;

                            @media (max-width:1000px) {
                                font-size: 23px;
                            }
                        }

                        div {
                            padding: 5px;
                            left: 0;
                            top: 5px;
                            margin: 0;
                            width: 15px;
                            height: 15px;
                            display: flex;
                            color: black;
                            font-size: 12px;
                            border-radius: 50%;
                            position: absolute;
                            align-items: center;
                            justify-content: center;
                            background-color: #FFE03A;

                            @media (max-width:1000px) {
                                width: 13px;
                                height: 13px;
                                font-size: 10px;
                            }
                        }
                    }

                    .volume {
                        font-size: 32px;
                        cursor: pointer;

                        @media (max-width:1000px) {
                            font-size: 25px;
                        }
                    }
                }

                .language {
                    display: flex;
                    padding-right: 0;
                    position: relative;
                    align-items: center;
                    justify-content: center;

                    input[type="checkbox"] {
                        display: none;
                    }

                    label {
                        margin: 0;
                        cursor: pointer;
                        font-size: 18px;
                        font-weight: 500;
                        padding: 5px 10px;
                        border-radius: 5px;
                        background-color: #FBC100;

                        &:hover {
                            color: white;
                            background-color: black;
                        }

                        .fa {
                            font-weight: 600;
                        }
                    }

                    .language-menu {
                        top: 45px;
                        padding: 0;
                        overflow: hidden;
                        border: solid 1px;
                        border-radius: 5px;
                        position: absolute;
                        background-color: white;

                        li {
                            width: 100%;
                            cursor: pointer;
                            list-style: none;
                            padding: 3px 20px;

                            &:hover {
                                color: white;
                                background-color: black;
                            }
                        }
                    }
                }
            }
        }

        .bottom-navbar {
            display: flex;
            padding: 10px 10vw;
            align-items: center;
            background-color: white;
            justify-content: space-between;
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

            @media (max-width:900px) {
                padding: 10px 7vw;
            }

            @media (max-width:767px) {
                top: 0;
                padding: 0;
                z-index: 3;
                right: -100vw;
                height: 100vh;
                position: fixed;
                transition: 0.4s;
                -webkit-transition: 0.4s;
                -moz-transition: 0.4s;
                -ms-transition: 0.4s;
                -o-transition: 0.4s;
                background-color: black;
            }

            .navbar-nav {
                @media (max-width:767px) {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    padding: 100px 0;
                    align-items: center;
                    flex-direction: column;
                    justify-content: space-between;
                }

                .nav-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .nav-link {
                        color: black;
                        cursor: pointer;
                        font-weight: 800;
                        padding: 7px 1.5vw;
                        font-family: zonapro;
                        &:hover{
                            color: #fba700;
                        }

                        @media (max-width:1000px) {
                            font-size: 14px;
                        }

                        @media (max-width:767px) {
                            color: white;
                        }
                    }
                }

                .category-menu {
                    z-index: 4;
                    cursor: pointer;
                    position: sticky;                    
                    padding: 7px 20px;
                    border-radius: 5px;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    -ms-border-radius: 5px;
                    -o-border-radius: 5px;
                    background-color: #FFC400;

                    @media (max-width:1000px) {
                        font-size: 15px;
                        padding: 5px 20px;
                    }

                    .category-tools {
                        top: 45px;
                        width: 200%;
                        overflow: auto;
                        max-height: 500px;
                        position: absolute;
                        border-radius: 7px;
                        -webkit-border-radius: 7px;
                        -moz-border-radius: 7px;
                        -ms-border-radius: 7px;
                        -o-border-radius: 7px;
                        border: solid 1px rgb(191, 191, 191);
                        background-color: white;

                        @media (max-width:900px) {
                            left: 0;
                            max-height: 400px;
                        }

                        @media (max-width:767px) {
                            left: auto;
                        }

                        .result {
                            color: black;
                            min-width: 100%;
                            padding: 5px 10px;
                            text-decoration: none;
                            transition: 0;
                            border-bottom: solid 1px #FFC400;
                            -webkit-transition: 0;
                            -moz-transition: 0;
                            -ms-transition: 0;
                            -o-transition: 0;

                            @media (max-width:1000px) {
                                font-size: 14px;
                            }

                            &:hover {
                                background-color: #FFC400;
                            }
                        }
                    }
                }
            }
        }

        .open-navbar {
            right: 0;
        }

        .fa-bars {
            color: black;
            font-size: 30px;
            cursor: pointer;

            @media (min-width:767.1px) {
                display: none;
            }

            @media (max-width:500px) {
                font-size: 28px;
            }
        }

        .fa-times {
            top: 20px;
            right: 30px;
            color: white;
            font-size: 35px;
            cursor: pointer;
            position: absolute;

            @media (min-width:767.1px) {
                display: none;
            }

            @media (max-width:500px) {
                font-size: 32px;
            }
        }
    }

    ::-webkit-scrollbar {
        width: 7px;
        color: rgb(202, 202, 202);
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #FFC400;
    }
}

.contr-search {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    position: fixed;
    background-color: transparent;
}

.contr-category {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10001;
    position: fixed;
    background-color: transparent;
}

.contr-category1 {
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
}

.contr-lang {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    position: fixed;
    background-color: transparent;
}

.cookie {
    position: fixed;
    width: 100%;
    height: 350px;
    bottom: 0;
    left: 0;
    z-index: 111111111111111;
    background-color: rgba(255, 255, 255, 0.83);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 30px 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @media (max-width:1500px) {
        padding: 20px 7vw;
    }

    @media (max-width:1000px) {
        height: 400px;
        padding: 20px 5vw;
    }

    @media (max-width:500px) {
        height: 500px;
    }

    p {
        display: block;
        width: 100%;

        @media (max-width:1500px) {
            font-size: 14px;
        }

        @media (max-width:700px) {
            font-size: 12px;
        }
    }

    a {
        padding: 0;
        margin: 0 10px;
        margin-top: 30px;

        @media (max-width:1500px) {
            font-size: 14px;
            margin-top: 15px;
        }

        @media (max-width:500px) {
            font-size: 14px;
            margin-top: 0px;
        }
    }

    .btn {
        border: solid 1px black;
        border-radius: 23px;
        transition: 0.3s;
        padding: 15px 30px;
        margin: 0 10px;
        margin-top: 30px;
        background-color: black;
        color: white;

        &:hover {
            background-color: white;
            color: black;
        }

        @media (max-width:1500px) {
            font-size: 14px;
            margin-top: 15px;
        }

        @media (max-width:500px) {
            font-size: 14px;
            margin-top: 0px;
        }

        @media (max-width:380px) {
            font-size: 14px;
            padding: 15px 20px;
        }
    }

    .fa-times {
        position: absolute;
        top: 20px;
        right: 30px;
        font-size: 35px;
        cursor: pointer;
    }
}

.contr-cookie {
    background-color: transparent;
    z-index: 1111111111;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}