.OneProduct {
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: sticky;
    overflow: hidden;

    .wrapper {
        padding: 0 10vw;
        padding-top: 12vw;

        @media (max-width:1200px) {
            padding-top: 15vw;
        }

        @media (max-width:1000px) {
            padding-top: 18vw;
        }

        @media (max-width:767px) {
            padding: 0 7vw;
            padding-top: 140px;
        }

        @media (max-width:500px) {
            padding-top: 130px;
        }

        .top-title {
            font-size: 2.3vw;

            &::first-letter {
                text-transform: uppercase;
            }

            @media (max-width:1000px) {
                font-size: 2.7vw;
            }

            @media (max-width:767px) {
                font-size: 3.5vw;
            }

            @media (max-width:500px) {
                font-size: 4.5vw;
            }
        }

        .product {
            gap: 3vw;
            display: grid;
            grid-template-columns: repeat(2, 2fr);

            @media (max-width:767px) {
                gap: 5vw;
                grid-template-columns: repeat(1, 1fr);
            }

            .imgs {
                gap: 2vw;
                width: 100%;
                display: flex;
                flex-direction: column;

                @media (max-width:767px) {
                    gap: 3vw;
                }

                .save-btn {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    .fa {
                        font-size: 2vw;
                        cursor: pointer;

                        @media (max-width:1000px) {
                            font-size: 2.5vw;
                        }

                        @media (max-width:767px) {
                            font-size: 5vw;
                        }
                    }

                    .fa-heart {
                        color: red;
                    }
                }

                .main-img {
                    width: 100%;
                    height: 25vw;
                    position: relative;
                    border: solid 1px rgb(153, 153, 153);

                    @media (max-width:767px) {
                        height: 40vw;
                    }

                    .img {
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        object-fit: contain;
                    }
                }

                .child-imgs {
                    gap: 1vw;
                    display: flex;
                    justify-content: space-between;

                    .child {
                        width: 22%;
                        height: 6vw;
                        cursor: pointer;
                        position: relative;
                        border: solid 1px rgb(153, 153, 153);

                        &:hover {
                            border-color: black;
                        }

                        @media (max-width:767px) {
                            height: 12vw;
                        }

                        .img {
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            object-fit: contain;
                        }
                    }
                }
            }

            .texts {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .desc {
                    font-size: 1.1vw;

                    &::first-letter {
                        text-transform: uppercase;
                    }

                    @media (max-width:1000px) {
                        font-size: 1.5vw;
                    }

                    @media (max-width:767px) {
                        font-size: 2.5vw;
                    }
                }

                .code {
                    margin-top: 1vw;
                    font-size: 1.1vw;

                    @media (max-width:1000px) {
                        font-size: 1.5vw;
                    }

                    @media (max-width:767px) {
                        font-size: 2.5vw;
                    }

                    span {
                        margin: 0 10px;
                        color: rgb(45, 130, 45);
                    }
                }

                .addcart-btn {
                    width: 100%;
                    margin-top: 1vw;
                    padding: 0.8vw 0;
                    font-size: 1.1vw;
                    border-radius: 60px;
                    -webkit-border-radius: 60px;
                    -moz-border-radius: 60px;
                    -ms-border-radius: 60px;
                    -o-border-radius: 60px;
                    border: solid 1px black;
                    background-color: transparent;

                    &:hover {
                        background-color: #FBC100;
                    }

                    @media (max-width:1000px) {
                        font-size: 1.5vw;
                    }

                    @media (max-width:767px) {
                        padding: 2vw 0;
                        font-size: 2.5vw;
                    }
                }
            }
        }

        .recomend {
            margin-top: 7vw;
            margin-bottom: 3vw;

            @media (max-width:767px) {
                margin-top: 9vw;
                margin-bottom: 4vw;
            }
        }

        .products {
            display: grid;
            gap: 3vw 1.5vw;
            grid-template-columns: repeat(4, 4fr);

            @media (max-width:1000px) {
                gap: 3vw 2vw;
                grid-template-columns: repeat(3, 3fr);
            }

            @media (max-width:767px) {
                gap: 4vw 3vw;
                grid-template-columns: repeat(2, 2fr);
            }
        }

        .paginations {
            display: flex;
            margin-top: 4vw;
            justify-content: center;

            @media (max-width:767px) {
                margin-top: 6vw;
            }

            @media (max-width:450px) {
                margin-top: 8vw;
            }
        }
    }
}