.ProductItem {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .imgs {
        width: 100%;
        height: 20vw;
        padding: 1vw;
        display: flex;
        overflow: hidden;
        position: relative;
        border-radius: 0.7vw;
        -webkit-border-radius: 0.7vw;
        -moz-border-radius: 0.7vw;
        -ms-border-radius: 0.7vw;
        -o-border-radius: 0.7vw;
        text-decoration: none;
        align-items: flex-start;
        border: solid 1px grey;

        @media (max-width:1000px) {
            height: 27vw;
            border-radius: 1.5vw;
            -webkit-border-radius: 1.5vw;
            -moz-border-radius: 1.5vw;
            -ms-border-radius: 1.5vw;
            -o-border-radius: 1.5vw;
        }

        @media (max-width:767px) {
            height: 40vw;
            padding: 2vw;
            border-radius: 2vw;
            -webkit-border-radius: 2vw;
            -moz-border-radius: 2vw;
            -ms-border-radius: 2vw;
            -o-border-radius: 2vw;
        }

        .top-tools {
            z-index: 2;
            width: 100%;
            display: flex;
            position: sticky;
            align-items: center;
            justify-content: space-between;

            .percent {
                color: white;
                cursor: pointer;
                font-size: 0.9vw;
                border-radius: 5px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                -ms-border-radius: 5px;
                -o-border-radius: 5px;
                padding: 0.2vw 0.7vw;
                background-color: red;

                @media (max-width:1000px) {
                    font-size: 1.2vw;
                }

                @media (max-width:767px) {
                    font-size: 2vw;
                    padding: 0.2vw 1vw;
                }
            }

            .save-btn {
                display: flex;
                padding: 0.5vw;
                cursor: pointer;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                align-items: center;
                justify-content: center;
                border: solid 1px black;
                background-color: white;

                @media (max-width:1000px) {
                    padding: 1.3vw;
                }

                .fa {
                    font-size: 1.5vw;

                    @media (max-width:1000px) {
                        font-size: 2vw;
                    }

                    @media (max-width:767px) {
                        font-size: 4vw;
                    }
                }

                .fa-heart {
                    color: red;
                }
            }
        }

        .img {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: white;

            img {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: contain;
            }
        }
    }

    .texts {
        text-decoration: none;

        .name {
            margin-top: 1vw;
            font-size: 1.1vw;
            margin-bottom: 0.5vw;
            color: rgba(0, 0, 0, 0.764);

            &::first-letter {
                text-transform: uppercase;
            }

            @media (max-width:1000px) {
                font-size: 1.7vw;
            }

            @media (max-width:767px) {
                font-size: 2.8vw;
            }

            .price {
                color: black;
                margin-left: 10px;
            }
        }

        .qqs {
            opacity: 0.6;
            color: black;
            font-size: 0.8vw;

            @media (max-width:1000px) {
                font-size: 1.1vw;
            }

            @media (max-width:767px) {
                font-size: 1.9vw;
            }
        }
    }

    .addcart-btn {
        width: 100%;
        padding: 0.8vw 0;
        margin-top: 1vw;
        font-size: 1.1vw;
        border-radius: 60px;
        -webkit-border-radius: 60px;
        -moz-border-radius: 60px;
        -ms-border-radius: 60px;
        -o-border-radius: 60px;
        border: solid 1px black;
        background-color: transparent;

        &:hover {
            background-color: #FBC100;
        }

        @media (max-width:1000px) {
            font-size: 1.5vw;
        }

        @media (max-width:767px) {
            font-size: 2.5vw;
        }
    }
}

.succes-modal {
    top: -30%;
    left: 35vw;
    z-index: 5;
    display: flex;
    position: fixed;
    padding: 3vw 4vw;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    border-radius: 1vw;
    -webkit-border-radius: 1vw;
    -moz-border-radius: 1vw;
    -ms-border-radius: 1vw;
    -o-border-radius: 1vw;
    align-items: center;
    justify-content: center;
    background-color: #FBC100;

    @media (max-width:767px) {
        left: 25vw;
        border-radius: 1.5vw;
        -webkit-border-radius: 1.5vw;
        -moz-border-radius: 1.5vw;
        -ms-border-radius: 1.5vw;
        -o-border-radius: 1.5vw;
    }

    @media (max-width:500px) {
        left: 20vw;
        padding: 5vw 6vw;
        border-radius: 2vw;
        -webkit-border-radius: 2vw;
        -moz-border-radius: 2vw;
        -ms-border-radius: 2vw;
        -o-border-radius: 2vw;
    }

    .succes-title {
        font-size: 1.8vw;
        margin: 0 !important;

        @media (max-width:1000px) {
            font-size: 2.3vw;
        }

        @media (max-width:767px) {
            font-size: 3vw;
        }

        @media (max-width:500px) {
            font-size: 4vw;
        }
    }
}

.show-succes-modal {
    top: 30%;
}