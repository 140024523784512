.About {
    padding: 0;
    margin: 0;

    .wrapper1 {
        .video {
            border-radius: 20px;
            overflow: hidden;
            margin-top: 150px;
            width: 90%;
            margin-left: 5%;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width:780px) {
                margin-top: 200px;
            }

            .vjs-big-play-button {
                border-radius: 50%;
                width: 100px;
                height: 100px;
                margin-left: -50px;

                @media (max-width:1350px) {
                    width: 80px;
                    height: 80px;
                    margin-left: -40px;
                }

                @media (max-width:1100px) {
                    width: 60px;
                    height: 60px;
                    margin-left: -30px;
                }

                @media (max-width:550px) {
                    width: 40px;
                    height: 40px;
                    margin-left: -20px;
                }

                @media (max-width:400px) {
                    width: 30px;
                    height: 30px;
                    margin-left: -15px;
                }

                span {
                    font-size: 80px;
                    display: flex;
                    align-items: center;

                    @media (max-width:1100px) {
                        font-size: 60px;
                    }

                    @media (max-width:550px) {
                        font-size: 40px;
                    }

                    @media (max-width:400px) {
                        font-size: 30px;
                    }
                }

                span::before {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .col-md-12 {
            padding: 100px 250px;

            // text-align: center;
            @media (max-width:1400px) {
                padding: 70px 150px;
            }

            @media (max-width:1000px) {
                padding: 30px 100px;
            }

            @media (max-width:800px) {
                padding: 30px 50px;
            }

            @media (max-width:500px) {
                padding: 0px 50px 30px 50px;
            }

            p {
                padding: 0 70px;
                text-align: justify;
                opacity: 0.7;
                line-height: none;
                font-size: 20px;

                @media (max-width:1100px) {
                    padding: 0 40px;
                }

                @media (max-width:650px) {
                    padding: 0 20px;
                }

                @media (max-width:500px) {
                    padding: 0;
                }
            }

            h1 {
                margin-top: 100px;
                font-size: 68px;
                text-align: left;
                padding: 20px 70px;
                font-family: 'Oswald', sans-serif;
                font-weight: 600;

                &::first-letter {
                    text-transform: uppercase;
                }

                @media (max-width:1400px) {
                    padding: 20px 50px;
                    font-size: 60px;
                }

                @media (max-width:1200px) {
                    font-size: 55px;
                }

                @media (max-width:900px) {
                    font-size: 50px;
                    padding: 20px 30px;
                    margin-top: 50px;
                }

                @media (max-width:500px) {
                    padding: 20px 0;
                    font-size: 40px;
                }

                @media (max-width:430px) {
                    padding: 20px 0;
                    font-size: 35px;
                }

                @media (max-width:380px) {
                    padding: 20px 0;
                    font-size: 30px;
                }
            }

            img {
                margin-top: 50px;
                min-width: 90%;
                max-height: 50vh;

                @media (max-width:500px) {
                    max-width: 100%;
                }
            }

            .date {
                text-align: left;
                padding: 0 70px 0px 70px;

                @media (max-width:1400px) {
                    padding: 0 50px 0px 50px;
                }

                @media (max-width:900px) {
                    padding: 0 30px;
                }

                @media (max-width:500px) {
                    padding: 20px 0;
                    font-size: 14px;
                }

                @media (max-width:460px) {
                    padding: 10px 0;
                    font-size: 12px;
                }

                @media (max-width:400px) {
                    padding: 5px 0;
                    font-size: 10px;
                }
            }

            .text {
                text-align: left;
                padding: 0px 70px;

                @media (max-width:1400px) {
                    padding: 0 50px;
                }

                @media (max-width:900px) {
                    padding: 0 30px;
                }

                @media (max-width:500px) {
                    padding: 0;
                }
            }
        }

        .videos {
            margin-top: 50px;

            .col-md-4 {
                padding: 15px;

                @media (max-width:500px) {
                    padding: 0px;
                    margin-top: 20px;
                }

                .body {
                    position: relative;
                    height: 300px;

                    @media (max-width:1200px) {
                        height: 250px;
                    }

                    iframe {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }

        .partners {
            display: flex;
            align-items: center;
            justify-content: center;
            // border: solid 1px;
            padding: 50px 10vw;

            @media (max-width:1629px) {
                padding: 50px 5vw;
            }

            @media (max-width:1555px) {
                padding: 50px 50px;
            }

            @media (max-width:1250px) {
                padding: 50px 80px;
            }

            @media (max-width:1000px) {
                padding: 50px 60px;
            }

            @media (max-width:870px) {
                padding: 50px 30px;
            }

            @media (max-width:767px) {
                padding: 50px 30px;
            }

            h1 {
                font-size: 68px;
                font-family: 'Oswald', sans-serif;
                font-weight: 600;
                margin: 0;

                @media (max-width:1400px) {
                    font-size: 60px;
                }

                @media (max-width:1200px) {
                    font-size: 55px;
                }

                @media (max-width:900px) {
                    font-size: 50px;
                }

                @media (max-width:500px) {
                    font-size: 40px;
                }

                @media (max-width:430px) {
                    font-size: 35px;
                }

                @media (max-width:380px) {
                    font-size: 30px;
                }
            }

            .col-4 {
                height: 300px;
                display: flex;
                position: relative;
                align-items: center;
                justify-content: center;

                @media (max-width:1200px) {
                    height: 230px;
                }

                @media (max-width:1000px) {
                    height: 200px;
                }

                @media (max-width:700px) {
                    height: 150px;
                }

                @media (max-width:500px) {
                    height: 120px;
                }

                @media (max-width:420px) {
                    height: 90px;
                }

                img {
                    left: 0;
                    width: 100%;
                    height: auto;
                    position: absolute;
                }
            }
        }
    }
}