.Categories {
    padding: 0;
    margin: 0;

    .wrapper {
        padding: 50px 10vw;
        padding-top: 10px;

        @media (max-width:767px) {
            padding: 50px 7vw;
            padding-top: 10px;
        }

        .outer {
            padding: 2vw;
            border-radius: 20px;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            background-color: #97979717;

            @media (max-width:767px) {
                border-radius: 15px;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
            }
        }

        .main-title {
            margin-top: 160px;

            @media (max-width:780px) {
                margin-top: 120px;
            }
        }

        .category {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            @media (max-width:1100px) {
                display: grid;
                grid-template-columns: repeat(4, 4fr);
            }

            @media (max-width:767px) {
                display: grid;
                grid-template-columns: repeat(2, 2fr);
            }

            .one {
                width: 20%;
                padding: 20px 1.1vw;

                @media (max-width:1100px) {
                    width: 100%;
                    padding: 10px 15px;
                }

                @media (max-width:900px) {
                    padding: 10px;
                }

                @media (max-width:767px) {
                    width: 100%;
                    padding: 10px 20px;
                }

                @media (max-width:500px) {
                    padding: 10px;
                }

                .body {
                    text-decoration: none;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    transition: 0.3s;
                    perspective: 10px;
                    transform-style: preserve-3d;

                    &:hover {
                        .img {
                            transform: translateY(-15px);
                            -webkit-transform: translateY(-15px);
                            -moz-transform: translateY(-15px);
                            -ms-transform: translateY(-15px);
                            -o-transform: translateY(-15px);

                            .prod-length {
                                bottom: 0;
                            }
                        }
                    }

                    .img {
                        max-width: 100%;
                        min-width: 100%;
                        max-height: 17vw;
                        min-height: 17vw;
                        position: relative;
                    }

                    .img {
                        width: 100%;
                        height: 17vw;
                        overflow: hidden;
                        transition: 0.3s;
                        border-radius: 13px;
                        -webkit-border-radius: 13px;
                        -moz-border-radius: 13px;
                        -ms-border-radius: 13px;
                        -o-border-radius: 13px;

                        @media (max-width:1100px) {
                            max-height: 21vw;
                            min-height: 21vw;
                            border-radius: 9px;
                            -webkit-border-radius: 9px;
                            -moz-border-radius: 9px;
                            -ms-border-radius: 9px;
                            -o-border-radius: 9px;
                        }

                        @media (max-width:767px) {
                            max-height: 40vw;
                            min-height: 40vw;
                            border-radius: 9px;
                            -webkit-border-radius: 9px;
                            -moz-border-radius: 9px;
                            -ms-border-radius: 9px;
                            -o-border-radius: 9px;
                        }

                        @media (max-width:500px) {
                            max-height: 43vw;
                            min-height: 43vw;
                        }

                        img {
                            max-width: 100%;
                            min-width: 100%;
                            max-height: 100%;
                            min-height: 100%;
                            object-fit: cover;
                        }

                        .prod-length {
                            left: 0;
                            z-index: 2;
                            width: 100%;
                            bottom: -6vw;
                            padding: 1vw;
                            transition: 0.3s;
                            color: black;
                            position: absolute;
                            background-color: #ffc400c3;
                            -webkit-transition: 0.3s;
                            -moz-transition: 0.3s;
                            -ms-transition: 0.3s;
                            -o-transition: 0.3s;

                            @media (max-width:1200px) {
                                font-size: 13px;
                            }

                            @media (max-width:767px) {
                                padding: 2vw;
                                bottom: -8vw;
                            }

                            @media (max-width:500px) {
                                font-size: 11px;
                                bottom: -10vw;
                            }
                        }
                    }

                    .name {
                        margin: 0;
                        width: 100%;
                        color: black;
                        font-size: 1.3vw;
                        margin-top: 15px;
                        transition: 0.3s;
                        text-align: center;

                        @media (max-width:767px) {
                            font-size: 2.5vw;
                        }
                    }
                }
            }
        }

        .more {
            padding: 0;
            width: 100%;
            display: flex;
            margin-top: 2vw;
            align-items: center;
            justify-content: end;

            .explore {
                cursor: pointer;
                padding: 7px 30px;
                font-size: 18px;
                border-radius: 7px;
                background-color: #9797972b;
                -webkit-border-radius: 7px;
                -moz-border-radius: 7px;
                -ms-border-radius: 7px;
                -o-border-radius: 7px;

                &:hover {
                    background-color: #FFC400;
                }

                @media (max-width:900px) {
                    font-size: 14px;
                }

                @media (max-width:500px) {
                    font-size: 13px;
                }

                .down-icon {
                    padding: 2px;
                    margin-left: 10px;
                    border-radius: 50%;
                    border: solid 1px black;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;

                    @media (max-width:900px) {
                        font-size: 18px;
                        margin-left: 6px;
                    }

                    @media (max-width:500px) {
                        padding: 0;
                        font-size: 16px;
                    }
                }
            }
        }

        .slide-tools {
            padding-left: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2vw;

            @media (max-width:500px) {
                // margin-bottom: 20px;
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            .d-flex {
                align-items: flex-end;
                justify-content: center;

                @media (max-width:500px) {
                    width: 100%;
                    justify-content: space-between;
                }

                h1 {
                    font-size: 1.7vw;
                    margin: 0;

                    @media (max-width:1100px) {
                        font-size: 2vw;
                    }

                    @media (max-width:767px) {
                        font-size: 2.3vw;
                    }

                    @media (max-width:500px) {
                        font-size: 3.3vw;
                    }
                }

                p {
                    margin: 0;
                    font-size: 1vw;
                    margin-left: 10px;

                    @media (max-width:1100px) {
                        font-size: 1.2vw;
                    }

                    @media (max-width:767px) {
                        font-size: 1.5vw;
                    }

                    @media (max-width:500px) {
                        display: none;
                    }
                }


                .nav-link {
                    margin: 0;
                    padding: 0;
                    margin-left: 15px;
                    display: flex;
                    font-size: 1vw;
                    color: #FFC400;
                    align-items: center;
                    text-decoration: underline;

                    @media (max-width:1100px) {
                        font-size: 1.2vw;
                    }

                    @media (max-width:767px) {
                        font-size: 1.5vw;
                    }

                    @media (max-width:500px) {
                        font-size: 2.3vw;
                    }
                }
            }

            .c2 {
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media (max-width:500px) {
                    margin-top: 15px;
                    width: 100%;
                }

                i {
                    width: 2.2vw;
                    height: 2.2vw;
                    font-size: 1.5vw;
                    border-radius: 50%;
                    border: solid 1px grey;
                    color: grey;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    @media (max-width:900px) {
                        font-size: 18px;
                        width: 23px;
                        height: 23px;
                    }

                    @media (max-width:550px) {
                        font-size: 16px;
                        width: 20px;
                        height: 20px;
                    }

                    @media (max-width:550px) {
                        font-size: 14px;
                        width: 17px;
                        height: 17px;
                    }
                }

                i.active {
                    color: black;
                    border-color: black;
                }
            }
        }
    }
}