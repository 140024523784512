.News {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .wrapper {
        padding: 70px 10vw;
        padding-bottom: 30px;

        @media (max-width:1629px) {
            padding: 50px 5vw;
            padding-bottom: 30px;
        }

        @media (max-width:1555px) {
            padding: 50px 50px;
            padding-bottom: 30px;
        }

        @media (max-width:1250px) {
            padding: 50px 80px;
            padding-bottom: 30px;
        }

        @media (max-width:1000px) {
            padding: 50px 60px;
            padding-bottom: 30px;
        }

        @media (max-width:870px) {
            padding: 50px 30px;
            padding-bottom: 30px;
        }

        @media (max-width:767px) {
            padding: 50px 30px;
            padding-bottom: 30px;
        }

        .one-new {
            margin-top: 80px;

            @media (max-width:780px) {
                margin-top: 150px;
            }

            .one-name {
                @media (max-width:600px) {
                    font-size: 24px;
                }
            }

            .one-img {
                width: 60%;
                margin-left: 20%;
                margin-top: 50px;

                @media (max-width:600px) {
                    width: 80%;
                    margin-left: 10%;
                    margin-top: 20px;
                }

                @media (max-width:600px) {
                    width: 100%;
                    margin-left: 0;
                }
            }

            .one-desc {
                margin-top: 50px;
                margin-bottom: 100px;

                @media (max-width:600px) {
                    margin-top: 20px;
                    text-align: justify;
                    font-size: 14px;
                    margin-bottom: 50px;
                }

                @media (max-width:450px) {
                    margin-bottom: 30px;
                }
            }
        }

        .title {
            color: black;
            text-align: center;

            @media (max-width:600px) {
                font-size: 24px;
            }
        }

        .carousel {
            // border: solid 1px red;  
            margin-top: 30px;
            display: flex;
            flex-wrap: wrap;

            .news {
                padding: 1.6vw;
                // border: solid 1px;

                @media (max-width:600px) {
                    width: 50%;
                }

                @media (max-width:450px) {
                    padding: 0;
                }

                .body {
                    width: 100%;
                    border-radius: 10px;
                    height: 100%;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    text-decoration: none;
                    align-items: flex-start;
                    justify-content: space-between;
                    padding: 1.3vw;
                    transition: 0.3s;
                    -webkit-border-radius: 10px;
                    -moz-border-radius: 10px;
                    -ms-border-radius: 10px;
                    -o-border-radius: 10px;
                    -webkit-transition: 0.3s;
                    -moz-transition: 0.3s;
                    -ms-transition: 0.3s;
                    -o-transition: 0.3s;

                    @media (max-width:600px) {
                        padding: 15px;
                    }

                    &:hover {
                        box-shadow: 0px 0px 43.357px rgba(25, 90, 148, 0.15);
                    }

                    img {
                        max-width: 100%;
                        min-width: 100%;
                        max-height: 15vw;
                        min-height: 15vw;
                        border-radius: 6px;
                        -webkit-border-radius: 6px;
                        -moz-border-radius: 6px;
                        -ms-border-radius: 6px;
                        -o-border-radius: 6px;

                        @media (max-width:600px) {
                            max-height: 25vw;
                            min-height: 25vw;
                        }
                    }

                    .name {
                        // border: solid 1px;
                        margin-top: 15px;
                        overflow: hidden;
                        color: black;

                        @media (max-width:900px) {
                            margin-top: 10px;
                            font-size: 14px;
                            margin-bottom: 3px;
                        }
                    }

                    .desc {
                        // border: solid 1px;
                        overflow: hidden;
                        color: black;
                        opacity: 0.7;
                        font-size: 15px;

                        @media (max-width:900px) {
                            font-size: 12px;
                            margin-bottom: 3px;
                        }
                    }

                    .explore {
                        color: black;
                        transition: 0.3s;
                        padding: 3px 30px;
                        border: solid 1px black;
                        border-radius: 15px;
                        -webkit-border-radius: 30px;
                        -moz-border-radius: 15px;
                        -ms-border-radius: 15px;
                        -o-border-radius: 15px;
                        -webkit-border-radius: 15px;

                        &:hover {
                            background-color: #FFC400;
                        }

                        @media (max-width:900px) {
                            font-size: 13px;
                            padding: 3px 20px;
                        }

                        @media (max-width:450px) {
                            font-size: 12px;
                            padding: 1px 10px;
                        }
                    }
                }
            }
        }
    }
}