.Footer {
    z-index: 3;
    width: 100%;
    position: sticky;
    overflow: hidden;
    background-color: #2C2C2C;

    .wrapper {
        gap: 1.5vw;
        display: grid;
        padding: 3vw 7vw;
        grid-template-columns: repeat(5, 5fr);

        @media (max-width:767px) {
            gap: 3vw;
            padding: 5vw;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .tool {
            width: 100%;
            display: flex;
            flex-direction: column;

            @media (max-width:767px) {
                width: 30%;

                &:last-child {
                    width: 65%;
                }
            }

            .logo {
                width: 7vw;
                margin-bottom: 1vw;

                @media (max-width:767px) {
                    width: 13vw;
                }

                .img {
                    max-width: 100%;
                    min-width: 100%;
                }
            }

            .footer-text {
                margin: 0;
                color: white;
                font-size: 0.9vw;
                text-decoration: none;

                &::first-letter {
                    text-transform: uppercase;
                }

                @media (max-width:767px) {
                    font-size: 2vw;
                }

                @media (max-width:450px) {
                    font-size: 2.7vw;
                }
            }

            .links {
                opacity: 0.7;
                margin-top: 0.5vw;

                &:hover {
                    text-decoration: underline;
                }

                @media (max-width:767px) {
                    margin-top: 1vw;
                }
            }

            .footer-title {
                color: white;
                font-size: 1.1vw;
                margin-bottom: 0.5vw;

                @media (max-width:767px) {
                    font-size: 2.5vw;
                }

                @media (max-width:450px) {
                    font-size: 3.2vw;
                }
            }

            .icons {
                gap: 2vw;
                margin: 1vw 0;
                display: flex;
                align-items: center;

                @media (max-width:767px) {
                    gap: 4vw;
                    margin: 1.5vw 0;
                }

                .fa {
                    color: white;
                    font-size: 2vw;
                    transition: 0.2s;
                    -webkit-transition: 0.2s;
                    -moz-transition: 0.2s;
                    -ms-transition: 0.2s;
                    -o-transition: 0.2s;

                    &:hover {
                        transform: scale(1.2);
                        -webkit-transform: scale(1.2);
                        -moz-transform: scale(1.2);
                        -ms-transform: scale(1.2);
                        -o-transform: scale(1.2);
                    }

                    @media (max-width:767px) {
                        font-size: 4vw;
                    }

                    @media (max-width:450px) {
                        font-size: 6vw;
                    }
                }
            }

            .form-control {
                width: 100%;
                border: none;
                outline: none;
                color: black;
                padding: 0.7vw 1vw;
                border-radius: 50px;
                background-color: white;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                -ms-border-radius: 50px;
                -o-border-radius: 50px;

                @media (max-width:767px) {
                    margin-top: 1vw;
                    padding: 1.5vw 2vw;
                }
            }

            .red-line {
                border: solid 1px red;
            }

            .subs-btn {
                width: auto;
                border: none;
                display: flex;
                color: white;
                margin-top: 1vw;
                cursor: pointer;
                transition: 0.2s;
                align-items: center;
                -webkit-transition: 0.2s;
                -moz-transition: 0.2s;
                -ms-transition: 0.2s;
                -o-transition: 0.2s;
                padding: 0.7vw 1.5vw;
                border-radius: 50px;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                -ms-border-radius: 50px;
                -o-border-radius: 50px;
                background-color: black;
                justify-content: flex-start;

                &:hover {
                    transform: scale(1.05);
                    -webkit-transform: scale(1.05);
                    -moz-transform: scale(1.05);
                    -ms-transform: scale(1.05);
                    -o-transform: scale(1.05);
                }

                @media (max-width:767px) {
                    margin-top: 2vw;
                    padding: 1.5vw 4vw;
                }
            }
        }
    }

    // .wrapper {
    //     padding: 50px 8vw;

    //     @media (max-width: 767px) {
    //         padding: 50px 5vw;
    //     }

    //     .logo {
    //         max-width: 7vw;
    //         min-width: 7vw;
    //         margin-bottom: 20px;

    //         @media (max-width:780px) {
    //             max-height: 50px;
    //             min-width: 110px;
    //         }

    //         @media (max-width:420px) {
    //             max-height: 40px;
    //             min-width: 90px;
    //         }
    //     }

    //     .f1 {
    //         align-items: flex-start;
    //         justify-content: space-between;

    //         @media (max-width:767px) {
    //             display: grid;
    //             grid-template-columns: repeat(3, 1fr);
    //             justify-content: space-between;
    //         }

    //         @media (max-width:550px) {
    //             display: grid;
    //             grid-template-columns: repeat(2, 1fr);
    //             justify-content: space-between;
    //         }

    //         .c1 {
    //             padding: 10px;

    //             @media (max-width:767px) {
    //                 width: 100%;
    //             }

    //             h3 {
    //                 color: white;

    //                 @media (max-width:900px) {
    //                     font-size: 18px;
    //                 }
    //             }

    //             p {
    //                 color: white;

    //                 @media (max-width:900px) {
    //                     font-size: 12px;
    //                 }
    //             }

    //             .icons {
    //                 display: flex;
    //                 align-items: center;
    //                 justify-content: start;

    //                 a {
    //                     color: black;
    //                     margin-right: 30px;
    //                     cursor: pointer;
    //                 }

    //                 i {
    //                     font-size: 22px;
    //                     color: white;

    //                     @media (max-width:900px) {
    //                         font-size: 18px;
    //                     }
    //                 }
    //             }
    //         }

    //         .c2 {
    //             padding: 10px;

    //             @media (max-width:767px) {
    //                 width: 100%;
    //             }

    //             ul {
    //                 @media (max-width:767px) {
    //                     padding: 0;
    //                 }

    //                 li {
    //                     list-style: none;
    //                     padding: 0;

    //                     a {
    //                         text-decoration: none;
    //                         color: white;
    //                         opacity: 0.7;

    //                         &:hover {
    //                             text-decoration: underline;
    //                         }

    //                         @media (max-width:900px) {
    //                             font-size: 12px;
    //                         }

    //                         @media (max-width:767px) {
    //                             padding-left: 0;
    //                             padding-right: 0;
    //                         }
    //                     }
    //                 }

    //                 .title {
    //                     font-weight: 600;
    //                     padding-left: 15px;
    //                     margin-bottom: 10px;
    //                     color: white;

    //                     @media (max-width:900px) {
    //                         font-size: 12px;
    //                     }

    //                     @media (max-width:767px) {
    //                         padding: 0;
    //                     }
    //                 }
    //             }
    //         }

    //         .c3 {
    //             padding: 13px 10px;
    //             display: block;

    //             @media (max-width:767px) {
    //                 display: none;
    //             }

    //             h6 {
    //                 color: white;

    //                 @media (max-width:900px) {
    //                     font-size: 12px;
    //                 }
    //             }

    //             input {
    //                 margin-top: 30px;
    //                 padding: 13px;
    //                 border-radius: 24px;
    //                 outline: none;
    //                 border: none;
    //                 display: block;

    //                 @media (max-width:900px) {
    //                     font-size: 12px;
    //                     padding: 10px;
    //                     margin-top: 25px;
    //                 }
    //             }

    //             .red-line {
    //                 border: solid 1px red;
    //             }

    //             .subs-btn {
    //                 // background: linear-gradient(137.88deg, #1DE3CC 4.24%, #18E789 91.51%);
    //                 background-color: black;
    //                 border-radius: 24px;
    //                 padding: 13px 30px;
    //                 font-weight: 600;
    //                 color: white;
    //                 border: none;
    //                 margin-top: 20px;
    //                 transition: 0.3s;
    //                 margin-bottom: 20px;

    //                 &:hover {
    //                     transform: scale(1.05);
    //                 }

    //                 &:active {
    //                     transform: scale(0.95);
    //                 }

    //                 @media (max-width:900px) {
    //                     font-size: 12px;
    //                     padding: 10px 30px;
    //                 }
    //             }

    //             a {
    //                 text-decoration: none;
    //                 color: white;
    //                 opacity: 0.7;

    //                 &:hover {
    //                     text-decoration: underline;
    //                 }

    //                 @media (max-width:900px) {
    //                     font-size: 12px;
    //                 }
    //             }
    //         }

    //         .c4 {
    //             padding: 13px 10px;
    //             display: block;
    //             width: 100%;

    //             @media (min-width:767.1px) {
    //                 display: none;
    //             }

    //             h6 {
    //                 color: white;

    //                 @media (max-width:900px) {
    //                     font-size: 12px;
    //                 }
    //             }

    //             input {
    //                 margin-top: 30px;
    //                 padding: 13px;
    //                 border-radius: 24px;
    //                 outline: none;
    //                 border: none;
    //                 display: block;

    //                 @media (max-width:1150px) {
    //                     padding: 10px;
    //                     width: 90%;
    //                 }

    //                 @media (max-width:900px) {
    //                     font-size: 12px;
    //                     padding: 10px;
    //                     margin-top: 25px;
    //                 }

    //                 @media (max-width:400px) {
    //                     width: 100%;
    //                 }
    //             }

    //             .red-line {
    //                 border: solid 1px red;
    //             }

    //             .subs-btn {
    //                 // background: linear-gradient(137.88deg, #1DE3CC 4.24%, #18E789 91.51%);
    //                 background-color: black;
    //                 border-radius: 24px;
    //                 padding: 13px 30px;
    //                 font-weight: 600;
    //                 color: white;
    //                 border: none;
    //                 margin-top: 20px;
    //                 margin-bottom: 20px;
    //                 transition: 0.3s;

    //                 &:hover {
    //                     transform: scale(1.05);
    //                 }

    //                 &:active {
    //                     transform: scale(0.95);
    //                 }

    //                 @media (max-width:900px) {
    //                     font-size: 12px;
    //                     padding: 10px 30px;
    //                 }
    //             }

    //             a {
    //                 text-decoration: none;
    //                 color: white;
    //                 opacity: 0.7;
    //                 font-size: 12px;
    //             }
    //         }
    //     }
    // }
}