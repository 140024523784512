.TopProducts {
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: sticky;
    overflow: hidden;

    .wrapper {
        padding: 0 10vw;

        @media (max-width:767px) {
            padding: 0 7vw;
        }

        .head {
            gap: 1.5vw;
            display: flex;
            margin-bottom: 2vw;
            align-items: flex-end;

            @media (max-width:500px) {
                margin-bottom: 3vw;
            }

            .prod-length {
                margin: 0;
                font-size: 1.3vw;

                @media (max-width:1000px) {
                    font-size: 1.7vw;
                }

                @media (max-width:767px) {
                    font-size: 2.3vw;
                }

                @media (max-width:500px) {
                    font-size: 3vw;
                }
            }
        }

        .top-title {
            margin: 0;
            font-size: 2.3vw;

            @media (max-width:1000px) {
                font-size: 2.7vw;
            }

            @media (max-width:767px) {
                font-size: 3.5vw;
            }

            @media (max-width:500px) {
                font-size: 4.5vw;
            }
        }

        .products {
            padding: 2vw;
            display: grid;
            gap: 3vw 1.5vw;
            border-radius: 20px;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            background-color: #97979717;
            grid-template-columns: repeat(4, 4fr);

            @media (max-width:1000px) {
                gap: 3vw 2vw;
                grid-template-columns: repeat(3, 3fr);
            }

            @media (max-width:767px) {
                gap: 4vw 3vw;
                border-radius: 15px;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                grid-template-columns: repeat(2, 2fr);
            }
        }

        .more {
            padding: 0;
            width: 100%;
            display: flex;
            margin-top: 2vw;
            align-items: center;
            justify-content: end;

            .explore {
                cursor: pointer;
                padding: 7px 30px;
                font-size: 18px;
                border-radius: 7px;
                background-color: #9797972b;
                -webkit-border-radius: 7px;
                -moz-border-radius: 7px;
                -ms-border-radius: 7px;
                -o-border-radius: 7px;

                &:hover {
                    background-color: #FFC400;
                }

                @media (max-width:900px) {
                    font-size: 14px;
                }

                @media (max-width:500px) {
                    font-size: 13px;
                }

                .down-icon {
                    padding: 2px;
                    margin-left: 10px;
                    border-radius: 50%;
                    border: solid 1px black;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;

                    @media (max-width:900px) {
                        font-size: 18px;
                        margin-left: 6px;
                    }

                    @media (max-width:500px) {
                        padding: 0;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}