.CartProducts {
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: sticky;
    overflow: hidden;

    .wrapper {
        padding: 0 10vw;
        padding-top: 12vw;

        @media (max-width:1200px) {
            padding-top: 15vw;
        }

        @media (max-width:1000px) {
            padding-top: 18vw;
        }

        @media (max-width:767px) {
            padding: 0 7vw;
            padding-top: 140px;
        }

        @media (max-width:500px) {
            padding-top: 130px;
        }

        .top-title {
            font-size: 2.3vw;
            margin-bottom: 2vw;

            @media (max-width:1000px) {
                font-size: 2.7vw;
            }

            @media (max-width:767px) {
                font-size: 3.5vw;
                margin-bottom: 5vw;
            }

            @media (max-width:500px) {
                font-size: 4.5vw;
            }
        }

        .products {
            gap: 1.5vw;
            display: flex;
            flex-direction: column;

            @media (max-width:1000px) {
                gap: 2vw;
            }

            @media (max-width:767px) {
                gap: 3vw;
            }

            .product {
                gap: 3vw;
                display: flex;
                padding: 1.5vw;
                border-radius: 1vw;
                -webkit-border-radius: 1vw;
                -moz-border-radius: 1vw;
                -ms-border-radius: 1vw;
                -o-border-radius: 1vw;
                align-items: center;
                border: solid 1px rgb(160, 160, 160);
                justify-content: space-between;

                @media (max-width:767px) {
                    border-radius: 1.5vw;
                    -webkit-border-radius: 1.5vw;
                    -moz-border-radius: 1.5vw;
                    -ms-border-radius: 1.5vw;
                    -o-border-radius: 1.5vw;
                }

                .img {
                    max-width: 9vw;
                    min-width: 9vw;
                    max-height: 9vw;
                    min-height: 9vw;
                    object-fit: contain;
                }

                .middle {
                    gap: 2vw;
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .top {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .name {
                            margin: 0;
                            opacity: 0.7;
                            color: black;
                            font-size: 1.1vw;

                            &::first-letter {
                                text-transform: uppercase;
                            }

                            @media (max-width:1000px) {
                                font-size: 1.7vw;
                            }

                            @media (max-width:767px) {
                                font-size: 2.8vw;
                            }
                        }

                        .price {
                            margin: 0;
                            opacity: 0.7;
                            color: black;
                            font-size: 0.9vw;

                            &::first-letter {
                                text-transform: uppercase;
                            }

                            @media (max-width:1000px) {
                                font-size: 1.5vw;
                            }

                            @media (max-width:767px) {
                                font-size: 2.5vw;
                            }
                        }
                    }

                    .bottom {
                        gap: 1vw;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .quantity {
                            margin: 0;
                            color: black;
                            font-size: 1.1vw;

                            &::first-letter {
                                text-transform: uppercase;
                            }

                            @media (max-width:1000px) {
                                font-size: 1.7vw;
                            }

                            @media (max-width:767px) {
                                font-size: 2.8vw;
                            }
                        }

                        .counter {
                            display: flex;
                            font-size: 1.2vw;
                            overflow: hidden;
                            border-radius: 0.7vw;
                            -webkit-border-radius: 0.7vw;
                            -moz-border-radius: 0.7vw;
                            -ms-border-radius: 0.7vw;
                            -o-border-radius: 0.7vw;
                            align-items: center;
                            border: solid 1px black;

                            @media (max-width:1000px) {
                                font-size: 1.7vw;
                            }

                            @media (max-width:767px) {
                                font-size: 2.8vw;
                            }

                            .count {
                                border: none;
                                color: white;
                                cursor: pointer;
                                padding: 0.5vw 2vw;
                                background-color: black;

                                &:active {
                                    opacity: 0.8;
                                }
                            }

                            .count-number {
                                padding: 0 4vw;
                            }
                        }
                    }
                }

                .icons {
                    gap: 2vw;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: space-between;

                    .fa {
                        cursor: pointer;
                        font-size: 1.5vw;

                        @media (max-width:1000px) {
                            font-size: 2vw;
                        }

                        @media (max-width:767px) {
                            font-size: 3.5vw;
                        }
                    }

                    .fa-heart {
                        color: red;
                    }
                }
            }

            .btn {
                font-size: 1.5vw;
                font-weight: 600;
                transition: 0.2s;
                -webkit-transition: 0.2s;
                -moz-transition: 0.2s;
                -ms-transition: 0.2s;
                -o-transition: 0.2s;
                padding: 1vw 10vw;
                border-radius: 50px;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                -ms-border-radius: 50px;
                -o-border-radius: 50px;
                background-color: #FBC100;

                &:hover {
                    transform: scale(1.05);
                    -webkit-transform: scale(1.05);
                    -moz-transform: scale(1.05);
                    -ms-transform: scale(1.05);
                    -o-transform: scale(1.05);
                }

                @media(max-width:1000px) {
                    font-size: 2vw;
                }

                @media (max-width:767px) {
                    font-size: 3.5vw;
                }
            }
        }
    }
}

.market-modal {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0 10vw;
    position: fixed;
    z-index: 111111;
    align-items: center;
    justify-content: center;

    @media (max-width:767px) {
        padding: 0 7vw;
    }

    .contr-mod {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.449);
    }

    .child-modal {
        z-index: 2;
        padding: 2vw;
        overflow: auto;
        max-height: 80vh;
        position: sticky;
        border-radius: 1vw;
        -webkit-border-radius: 1vw;
        -moz-border-radius: 1vw;
        -ms-border-radius: 1vw;
        -o-border-radius: 1vw;
        background-color: white;

        @media (max-width:767px) {
            padding: 3vw;
            border-radius: 1.5vw;
            -webkit-border-radius: 1.5vw;
            -moz-border-radius: 1.5vw;
            -ms-border-radius: 1.5vw;
            -o-border-radius: 1.5vw;
        }

        .form-control {
            margin-bottom: 1vw;

            @media (max-width:767px) {
                margin-bottom: 3vw;
            }
        }

        .recaptcha {
            height: 3vw;
        }

        .red-line {
            border: solid 1px red;
        }

        .btn {
            font-size: 1.5vw;
            font-weight: 500;
            transition: 0.2s;
            -webkit-transition: 0.2s;
            -moz-transition: 0.2s;
            -ms-transition: 0.2s;
            -o-transition: 0.2s;
            padding: 0.7vw 10vw;
            border-radius: 50px;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            -ms-border-radius: 50px;
            -o-border-radius: 50px;
            background-color: #FBC100;

            &:hover {
                transform: scale(1.05);
                -webkit-transform: scale(1.05);
                -moz-transform: scale(1.05);
                -ms-transform: scale(1.05);
                -o-transform: scale(1.05);
            }

            @media(max-width:1000px) {
                font-size: 2vw;
            }

            @media (max-width:767px) {
                width: 100%;
                padding: 1vw 0;
                font-size: 3.5vw;
            }
        }
    }
}