.SavedProducts {
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: sticky;
    overflow: hidden;

    .wrapper {
        padding: 0 10vw;
        padding-top: 12vw;

        @media (max-width:1200px) {
            padding-top: 15vw;
        }

        @media (max-width:1000px) {
            padding-top: 18vw;
        }

        @media (max-width:767px) {
            padding: 0 7vw;
            padding-top: 140px;
        }

        @media (max-width:500px) {
            padding-top: 130px;
        }

        .top-title {
            font-size: 2.3vw;
            margin-bottom: 3vw;

            @media (max-width:1000px) {
                font-size: 2.7vw;
            }

            @media (max-width:767px) {
                font-size: 3.5vw;
                margin-bottom: 5vw;
            }

            @media (max-width:500px) {
                font-size: 4.5vw;
                margin-bottom: 5vw;
            }

            .fa {
                color: red;
            }
        }

        .products {
            display: grid;
            gap: 3vw 1.5vw;
            grid-template-columns: repeat(4, 4fr);

            @media (max-width:1000px) {
                gap: 3vw 2vw;
                grid-template-columns: repeat(3, 3fr);
            }

            @media (max-width:767px) {
                gap: 4vw 3vw;
                grid-template-columns: repeat(2, 2fr);
            }
        }
    }
}