.Card {
    padding: 0;
    margin: 0;
    height: 100%;
    overflow: hidden;

    .wrapper {
        padding: 50px 10vw;
        // padding-top: 100px;

        @media (max-width:1629px) {
            padding: 70px 5vw;
            padding-top: 100px;
        }

        @media (max-width:1555px) {
            padding: 70px 50px;
            padding-top: 100px;
        }

        @media (max-width:1250px) {
            padding: 70px 80px;
            padding-top: 100px;
        }

        @media (max-width:1000px) {
            padding: 70px 60px;
            padding-top: 100px;
        }

        @media (max-width:870px) {
            padding: 70px 30px;
            padding-top: 100px;
        }

        @media (max-width:767px) {
            padding: 0px 30px;
        }

        h1 {
            padding-left: 10px;
        }

        .cards {
            .e1 {
                padding-right: 20px;

                @media (max-width:767px) {
                    padding: 0;
                }
            }

            .e2 {
                padding-left: 20px;

                @media (max-width:767px) {
                    padding: 0;
                }
            }

            .col-md-6 {
                margin-top: 30px;

                .body {
                    // border: solid 1px;
                    position: relative;
                    border-radius: 20px;
                    overflow: hidden;
                    padding: 40px 20px;
                    padding-left: 50%;

                    @media (max-width:1000px) {
                        padding: 20px;
                        padding-left: 50%;
                    }

                    @media (max-width:767px) {
                        padding: 50px;
                        padding-left: 50%;
                    }

                    @media (max-width:500px) {
                        padding: 30px;
                        padding-left: 50%;
                    }

                    @media (max-width:420px) {
                        padding: 15px;
                        padding-left: 50%;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        position: absolute;
                        z-index: -1;
                    }

                    span,
                    p {
                        text-align: left;
                        color: white;

                        @media (max-width:420px) {
                            font-size: 13px;
                        }
                    }

                    h3 {
                        text-align: left;
                        color: white;

                        @media (max-width:420px) {
                            font-size: 18px;
                        }
                    }

                    .shop {
                        background-color: #1AE287;
                        color: white;
                        padding: 13px 20px;
                        border-radius: 20px;
                        text-align: center;
                        width: 55%;

                        @media (max-width:1110px) {
                            width: 70%;
                        }

                        @media (max-width:900px) {
                            width: 75%;
                            font-size: 14px;
                            border-radius: 15px;
                            padding: 10px 20px;
                        }

                        @media (max-width:420px) {
                            font-size: 13px;
                            border-radius: 8px;
                            padding: 7px 20px;
                        }

                        @media (max-width:360px) {
                            width: 85%;
                        }
                    }
                }
            }
        }

        .col-md-12 {
            margin-top: 50px;

            .texts {
                width: 100%;
                padding-left: 10vw;
                display: flex;
                align-items: center;

                // border: solid 1px;
                @media (max-width:600px) {
                    padding-left: 0;
                }

                @media (max-width:450px) {
                    display: block;
                }

                .title {
                    line-height: 30px;

                    @media (max-width:1200px) {
                        font-size: 3vw;
                    }

                    @media (max-width:600px) {
                        font-size: 4.5vw;
                    }

                    @media (max-width:450px) {
                        font-size: 6vw;
                    }
                }

                p {
                    margin: 0;
                    margin-left: 15px;

                    @media (max-width:1200px) {
                        font-size: 1.5vw;
                    }

                    @media (max-width:600px) {
                        font-size: 2.5vw;
                    }

                    @media (max-width:450px) {
                        margin-left: 10px;
                        font-size: 3.5vw;
                    }
                }
            }

            .body {
                margin-top: 2vw;
                justify-content: space-between;

                @media (max-width:600px) {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                }

                .col-3 {
                    padding: 15px 1.5vw;

                    @media (max-width:600px) {
                        width: 100%;
                        padding: 10px;
                    }

                    .tool {
                        cursor: pointer;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        background-color: #F8F8F8;
                        padding: 1.1vw;
                        border-radius: 7px;
                        -webkit-border-radius: 7px;
                        -moz-border-radius: 7px;
                        -ms-border-radius: 7px;
                        -o-border-radius: 7px;
                        transition: 0.3s;
                        -webkit-transition: 0.3s;
                        -moz-transition: 0.3s;
                        -ms-transition: 0.3s;
                        -o-transition: 0.3s;

                        &:hover {
                            transform: scale(1.1);
                            -webkit-transform: scale(1.1);
                            -moz-transform: scale(1.1);
                            -ms-transform: scale(1.1);
                            -o-transform: scale(1.1);
                        }

                        @media(max-width:767px) {
                            display: block;
                            text-align: center;
                        }

                        @media (max-width:600px) {
                            padding: 1.5vw;
                        }

                        img {
                            max-width: 5.5vw;
                            min-width: 5.5vw;

                            @media (max-width:1100px) {
                                max-width: 7vw;
                                min-width: 7vw;
                            }

                            @media (max-width:600px) {
                                max-width: 10vw;
                                min-width: 10vw;
                            }

                            @media (max-width:400px) {
                                max-width: 12vw;
                                min-width: 12vw;
                            }
                        }

                        .icon {
                            color: #FBC100;
                            font-size: 6vw;

                            @media (max-width:1100px) {
                                font-size: 7.5vw;
                            }

                            @media (max-width:600px) {
                                font-size: 10.5vw;
                            }

                            @media (max-width:400px) {
                                font-size: 12vw;
                            }
                        }

                        h5 {
                            color: black;
                            text-align: center;
                            font-size: 1.1vw;
                            margin-top: 1vw;

                            @media (max-width:1100px) {
                                font-size: 1.7vw;
                            }

                            @media (max-width:800px) {
                                font-size: 2.3vw;
                            }

                            @media (max-width:600px) {
                                font-size: 3.2vw;
                            }

                            @media (max-width:400px) {
                                font-size: 3.7vw;
                            }
                        }

                        p {
                            color: black;
                            text-align: center;
                            font-size: 0.8vw;
                            margin-top: 1vw;

                            @media (max-width:1100px) {
                                font-size: 1.3vw;
                            }

                            @media (max-width:800px) {
                                font-size: 1.6vw;
                            }

                            @media (max-width:600px) {
                                font-size: 2.5vw;
                            }

                            @media (max-width:400px) {
                                font-size: 3vw;
                            }
                        }
                    }
                }
            }
        }
    }
}